$mobile-nav-icon-width: 28px;
$mobile-nav-icon-line-height: 3px;

// Mobile nav icon, animation
.mobile-nav-icon{
    margin-left: 32px;
    margin-top: 8px;

    /* Hamburger Menu */
    .mobile-icon {
        position: relative;
        // top: 50%;
        // left: 50%;
        // margin-left: calc(-44px / 2);
        // margin-top: calc(-44px / 2);
        display: inline-block;
        width: $mobile-nav-icon-width;
        height: calc(($mobile-nav-icon-width * 3) / 4);
        cursor: pointer;
        background: none;
        border: none;
        
        span {
            position: absolute;
            left: 0;
            width: $mobile-nav-icon-width;
            height: $mobile-nav-icon-line-height;
            background-color: #ffffff;

            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2) {
                top: 50%;
            }
            &:nth-child(3) {
                top: 100%;
            }
        }
    }
}

// Mobile navigation
.mobile-nav.active{
    transform: translateX(0);
}

.mobile-nav{
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    z-index: 999;
    padding-top: 80px;

    @include media-breakpoint-up(lg){
        position: static;
        background: none;
        width: 50%;
        height: auto;
        justify-content: center;
        transform: none;
        padding-top: 0;
        z-index: auto;
    }

    ul{
        display: block;

        @include media-breakpoint-up(lg){
            display: flex;
        }

        li{
            margin-bottom: 24px;

            @include media-breakpoint-up(lg){
                margin-bottom: 0;
            }

            a{
                font-size: 32px;
                font-weight: 700 !important;
                color: #fff !important;

                @include media-breakpoint-up(lg){
                    font-size: 17px;
                    font-weight: 400 !important;
                    color: #D2D2D2;
                }
            }
        }
    }
}
  
  